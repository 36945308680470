@import "styles/share";

.services-detail {
  color: var(--textColor);
  @include media-breakpoint-down(md) {
    background-size: cover!important;
    object-fit: cover;
  }
  :global {
    .wrapper {
      position: relative;
      height: 100%;
      background-attachment: scroll !important;
      background-size: contain !important;
      background-position: center top !important;

      @include media-breakpoint-up(md) {
        background-attachment: fixed !important;
        background-size: cover !important;
        background-position: center center !important;
      }
    }
  }
  &__overlay {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 20%;

    @include media-breakpoint-up (md) {
      bottom: 15%;
    }

    @include media-breakpoint-up(lg) {
      bottom: 20%;
    }
  }
  &__text-overlay {
    position: absolute;
    bottom: 17em;
    right: 6.5em;
    opacity: 0.63;
    color: var(--mainColor);
    font-family: var(--font-bebas-neue), sans-serif;
    font-size: 24px;
    letter-spacing: 1.92px;
    line-height: 28px;
    text-transform: uppercase;
    text-align: left;
    display: none;

    p {
      margin-left: 56px;
    }

    @include media-breakpoint-up(md) {
      display: block;
    }
  }
  &__red-circle {
    box-sizing: border-box;
    height: 34px;
    width: 34px;
    border: 5px solid var(--mainColor);
    opacity: 0.63;
    background-color: rgba(165, 0, 39, 0.1);
    border-radius: 50%;
    float: left;
    display: none;

    @include media-breakpoint-up(md) {
      display: block;
    }
  }
  &__bg-overlay {
    position: absolute;
    bottom: 0;
    right: 0;
    width: auto;
    height: auto;
    @include media-breakpoint-down(lg) {
      width: auto !important;
    }
    @include media-breakpoint-up(md) {
      width: auto !important;
    }
  }

  &__row-item {
    margin: 0 auto;
    padding-top: 80px;
    padding-bottom: 150px;
    position: relative;
    z-index: 10;

    @include media-breakpoint-down(md) {
      padding-bottom: 80px;
    }
    @include media-breakpoint-up(lg) {
      max-width: 11 / 12 * 100%;
    }
    @media (min-width: 1024.89px) {
      max-width: 10 / 12 * 100%;
    }
  }

  &__title-icon {
    width: 46px;
    height: auto;
  }

  &__title {
    font-family: var(--font-bebas-neue), sans-serif;
    font-size: 24px;
    line-height: 25px;
    text-align: center;
    margin: 16px 0 24px;

    @include media-breakpoint-up(md) {
      text-align: left;
      font-size: 32px;
      line-height: 38px;
      margin: 5px 0 50px;
    }
  }
  :global {
    .workout {
      img.workout {
        width: 46px;
        height: 46px;
      }
      p {
        line-height: 28px;
        @include media-breakpoint-down(md) {
          line-height: 24px;
          padding: 0 8px;
        }
      }
    }
    .tailored {
      .media {
        @include media-breakpoint-up(md) {
          margin-left: 38px;
        }
      }
    }
    img.diet {
      width: 55px;
      height: 46px;
    }
    .analytics {
      @include media-breakpoint-up(md) {
        margin-top: 90px;
      }
      img.analytic {
        width: 37px;
        height: 46px;
      }
    }
    img.community {
      width: 55px;
      height: 42px;
    }
    .social-group {
      .row {
        margin-bottom: 0px;
        align-items: center;
      }
    }
  }

  &__card-item {
    margin-bottom: 70px;

    @include media-breakpoint-up(md) {
      margin-bottom: 0;
    }

    p {
      text-align: center;
      font-size: 16px;
      line-height: 24px;
      margin: 0;
      padding: 0;

      @include media-breakpoint-up(md) {
        text-align: left;
      }
    }

    :global {
      .media {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-bottom: 30px;

        @include media-breakpoint-up(md) {
          flex-direction: row;
          align-items: flex-start;
          margin-top: 50px;
        }
      }

      .media-body {
        @include media-breakpoint-up(md) {
          padding-left: 27px;
          flex: 1;
        }
      }
    }
  }

  &__food-list {
    margin: 0;
    &__col-item {
      margin: 0;
    }
    :global {
      .thumbnail {
        position: relative;
        overflow: hidden;
        border-radius: 10px;
        border-width: 0;
      }

      .img-thumbnail {
        border-width: 0 !important;
        padding: 0!important;
        width: 100%;
        height: auto;
        border-radius: 0 !important;
        background-color: transparent;
      }

      .caption {
        background: rgba(0,0,0,0.3);
        color: #FFFFFF;
        padding: 7px 8px;
        font-family: var(--font-roboto), sans-serif;
        font-weight: bold;
        position: absolute;
        z-index: 1;
        left: 0;
        right: 0;
        bottom: 0;
        p {
          text-transform: uppercase;
          text-align: left;
          font-size: 11px;
          line-height: 13px;
          @include media-breakpoint-up(md) {
            font-size: 14px;
            line-height: 16px;
          }
        }
      }
    }
  }

  &__mobile-list {
    position: relative;
    // w 310 h 405
    height: 0;
    padding-top: 450 / 400 * 100%;
    width: 90%;
    @include media-breakpoint-up(md) {
      top: -95px;
    }
    img {
      object-fit: contain;
      width: 149px;
      height: 315px;
      position: absolute;
      width: 50%;

      &:nth-child(1) {
        left: 0;
        top: 90 / 405 * 100%;
        z-index: 1;
      }
      &:nth-child(2) {
        right: 0;
        top: 40 / 405 * 100%;
        z-index: 2;
      }
      &:nth-child(3) {
        top: 0;
        left: 50%;
        transform: translateX(-50%);
        z-index: 3;
      }
    }
  }

  :global {
    .fb-group-wrapper {
      @include media-breakpoint-down(lg) {
        margin-left: auto;
        margin-right: auto;
      }
    }
    .fb-group {
      width: 100%;
      @include media-breakpoint-down(md) {
        display: inline-block;
        text-align: center;
      }
    }
  }

  &__facebook-banner {
    &:hover,
    &:focus,
    &:active {
      text-decoration: none;
    }
  }

  .btn-facebook {
    background-color: #4267b3 !important;
    border-color: #4267b3 !important;
  }

  .facebook-group-name {
    color: #212121;
  }
}
