@import "styles/share";

.choose-program {
  &__wrapper {
    color: var(--textColor);
    padding: 92px 0 94px 0;
    position: relative;

    @include media-breakpoint-down(lg) {
      background-size: cover !important;
    }
  }
  &__caption {
    position: relative;
    z-index: 2;
    @include media-breakpoint-up(lg) {
      max-width: 990px;
      padding: 0;
    }

    &.program-list {
      max-width: 1290px;
    }
  }
  &__title {
    font-family: var(--font-bebas-neue), sans-serif;
    font-size: 60px;
    letter-spacing: 0;
    line-height: 65px;
    border-top: 4px solid var(--textColor);
    border-bottom: 4px solid var(--textColor);
    width: fit-content;
    margin: 0 auto;
    padding: 5px 0;
    color: var(--textColor);
  }
  &__list-service {
    margin: 48px auto 0 auto;
 
    @include media-breakpoint-down(md) {
      max-width: 320px;
    }

    @include media-breakpoint-up(md) {
      padding-left: 5px;
    }

    .col-12 {
      padding: 0;
    }
  }
  &__descriptions{
    p{
      margin-bottom: 2px ;
      font-weight: 800;
    }
    font-weight: 400;
  }
  
  :global {
    .nav-link {
      color: var(--textColor);
      font-family: var(--font-roboto), sans-serif;
      font-size: 16px;
      letter-spacing: 0;
      text-align: left;
      padding: 0.1rem;
      pointer-events: none;
      @include media-breakpoint-down(md) {
        padding: 0.5rem;
      }

      img {
        margin-right: 25px;
      }
    }
  }
  .img-path {
    position: absolute;
    top: 0;
    width: 62%;
    z-index: 1;
  }
  .img-blanket {
    position: absolute;
    top: 11em;
    left: 0em;
    width: 60%;
    z-index: 1;
  }
  &__card-list {
    margin: 100px auto 63px auto;
    justify-content: center;
    gap: 40px;

    :global {
      .card:last-child {
        button {
          @include media-breakpoint-down(lg) {
            padding: 15px;
          }
        }
      }
    }
  }

  :global {
    .card {
      padding: 0;
      border-radius: 7px 7px 4px 4px;
      border: 0;
      position: relative;

      @include media-breakpoint-up(lg) {
        min-width: 340px;
      }

      &.best-deal-card {
        flex-grow: 0.4;

        @include media-breakpoint-down(md) {
          margin-top: 35px;
        }
      }

      .best-deal {
        position: absolute;
        top: -45px;
        right: 0;
        left: 0;
        border-radius: 7px 7px 0 0;
        background-color: #fdd34e;
        color: #000;
        font-size: 20px;
        font-weight: 700;
        letter-spacing: 0px;
        line-height: 35px;
        padding: 5px 10px;
        text-transform: uppercase;
        z-index: 1;

        &-image {
          position: absolute;
          z-index: 1;
          bottom: 80px;
          right: 15px;
        }
      }

      .card-top {
        position: relative;
      }

      .card-title {
        background: linear-gradient(180deg, #fdd34e 0%, #f9a826 100%);
        padding: 10px 0;
        font-family: var(--font-bebas-neue), sans-serif;
        font-size: 24px;
        letter-spacing: 1.92px;
        line-height: 25px;
        margin-bottom: 0;
        color: var(--textColor);
      }

      .price {
        background-color: #56a71c;
        color: var(--textColor);
        position: absolute;
        right: 0;
        bottom: 0;
        border-radius: 7px 1px 1px 7px;
        font-family: var(--font-lato), sans-serif;
        font-size: 24px;
        font-weight: 900;
        letter-spacing: 0.3px;
        line-height: 24px;
        text-align: right;
        padding: 9px 8px 3px 13px;
      }

      .week {
        font-family: var(--font-roboto), sans-serif;
        font-size: 20px;
        font-weight: 500;
        letter-spacing: 0;
        line-height: 24px;
        padding: 16px 19px;
        margin: 0 17px 22px 19px;
        text-transform: uppercase;

        &:hover {
          background-color: #F9A826;
          color: #fff;
        }
      }
    }

    .card-middle {
      text-align: left;
      padding: 1em;
      background-color: #121212;
      border-radius: 0 0 4px 4px;
      box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 2px 1px -1px rgba(0, 0, 0, 0.12), 0 1px 3px 0 rgba(0, 0, 0, 0.2);
      font-family: var(--font-roboto), sans-serif;
      text-transform: uppercase;

      .choose-program__title {
        font-size: 20px;
        font-weight: 500;
        letter-spacing: 0;
        line-height: 24px;
        color: var(--textColor);
      }

      span {
        color: rgba(255, 255, 255, 0.87);
        font-size: 14px;
        font-weight: 500;
        letter-spacing: 0;
      }
    }

    .card-body {
      color: #000;
      text-align: left;
      padding: 20px;
    }

    .price .unit {
      font-size: 12px;
      font-family: var(--font-roboto), sans-serif;
      font-weight: 500;
    }

    .thumbnail img {
      width: 100%;
      height: auto;
    }
  }

  &__service-list {
    :global {
      .nav-item {
        background: url("/static/images/check-green-small.svg") no-repeat left top;
        background-position: 0px 8px;
        padding: 6px 9px 6px 21px;
        color: #1e1e1e;
        font-family: var(--font-roboto), sans-serif;
        font-size: 14px;
        letter-spacing: 0;
        vertical-align: middle;
      }
    }
    .tagline {
      margin-top: 15px;
    }
  }
}
