@import 'styles/share';

.simple-section {
  &__caption {
    width: 800px;
    height: auto;
    margin: 0 auto;
    font-family: var(--font-lato), sans-serif;
    font-size: 14px;
    letter-spacing: 0;
    line-height: 24px;
    text-align: center;
    padding: 98px 0 0 0;
    color: var(--textColor);
    font-family: var(--font-bebas-neue), sans-serif;
    p {
      font-family: var(--font-roboto), sans-serif;
      font-size: 16px;
      letter-spacing: 0;
      line-height: 24px;
      text-align: center;
      padding: 0 20px;
      margin: 40px auto;
      @include media-breakpoint-down(md) {
        font-size: 16px;
        padding: 0 16px;
      }
    }
    @include media-breakpoint-down(lg) {
      width: auto;
    }
  }
  &__wrapper {
    position: relative;
    width: 100%;
    height: auto;
    min-height: 893px;
    @include media-breakpoint-down(md) {
      min-height: 739px;
      background-position: left top !important;
      padding-bottom: 15px;
    }
    @media (min-width: 1580px) {
      min-height: 978px;
      object-fit: cover;
    }
  }
  .img-bg {
    width: 100%;
    height: auto;
  }
  &__title {
    font-size: 40px;
    letter-spacing: 1.6px;
    line-height: 43px;
    @include media-breakpoint-down(lg) {
      margin: 0 64px;
      font-size: 32px;
    }
  }
  &__list-symbol {
    margin: 42px auto 49px auto;
    .card {
      border: 0;
      padding: 0;
    }
    img {
      width: 52px;
      height: 52px;
      margin: 0 auto;
    }
    span {
      font-family: var(--font-roboto), sans-serif;
      font-size: 16px;
      font-weight: bold;
      letter-spacing: 0;
      line-height: 16px;
      margin-top: 1em;
      @include media-breakpoint-down(md) {
        font-size: 14px;
      }
    }
    @include media-breakpoint-up(md) {
      max-width: 500px;
    }
    @include media-breakpoint-down(lg) {
      max-width: unset;
    }
  }
  &__group-machine {
    margin-top: 3em;
    position: relative;
    @include media-breakpoint-up(md) {
      max-width: 850px;
      margin: 0 auto;
    }
  }
  &__imac {
    z-index: 1;
    @include media-breakpoint-down(md) {
      z-index: 1;
      width: 70%;
    }
  }
  &__ipad {
    position: absolute;
    left: 0em;
    bottom: -5em;
    z-index: 2;
    @include media-breakpoint-down(md) {
      position: absolute;
      left: 0em;
      bottom: 0;
      z-index: 2;
      width: 25%;
    }
  }
  &__iphone {
    position: absolute;
    left: 14em;
    bottom: -3em;
    z-index: 2;
    @include media-breakpoint-down(md) {
      position: absolute;
      left: 6em;
      bottom: 1em;
      z-index: 2;
      width: 15%;
    }
  }
  &__laptop {
    position: absolute;
    right: -5em;
    bottom: -5em;
    z-index: 2;
    @include media-breakpoint-down(lg) {
      right: 0;
    }
    @include media-breakpoint-down(md) {
        position: absolute;
        bottom: 0;
        z-index: 2;
        width: 57%;
    }
  }
}
