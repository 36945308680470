@import 'styles/share';

.logo-bar {
  position: relative;
  &__wrapper {
    max-width: 1374px;
    min-height: 124px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    @include media-breakpoint-down(lg) {
      flex-direction: column;
      min-height: 196px;
    }
    @include media-breakpoint-down(md) {
      min-height: 254px;
    }
  }
  .row {
    @include media-breakpoint-down(lg) {
      justify-content: space-around;
    }
  }
  &__title {
    font-family: var(--font-bebas-neue),sans-serif;
    font-weight: 400;
    font-size: 24px;
    line-height: 1;
    text-align: center;
    color: #454545;
    min-width: 120px;
    @include media-breakpoint-down(xl) {
      margin: 0 6px 0 0;
    }
    @include media-breakpoint-down(lg) {
      display: block;
      margin: 0;
      line-height: 28px;
    }
  }
  &__list-logo {
    margin: 0 60px;
    width: 100%;
    @include media-breakpoint-down(lg) {
      margin: 0;
    }
    img {
      margin: 15px 0 !important;
      width: auto !important;
      @include media-breakpoint-up(lg) {
        margin: 10px 0 !important;
      }
    }
  }
  &__featured__logos {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    flex-basis: 115px;
    justify-content: space-evenly;
    // grid-template-columns: minmax(117px, 115px) repeat(auto-fit, 115px);
    gap: 30px;
    @include media-breakpoint-down(lg) {
      justify-content: center;
    }
    @include media-breakpoint-down(sm) {
      gap: 24px;
      margin-bottom: 20px;
      justify-content: space-between;
    }
  }
  &__logo-item {
    @include media-breakpoint-down(sm) {
      &:nth-child(n + 10) {
        display: none;
      }
    }
  }
  &__last_logo {
    @include media-breakpoint-up(xs) {
      display: none;
    }
  }
  &__media {
    display: block;
    text-align: justify;
    img {
      @include media-breakpoint-down(lg) {
        margin: 15px 4px !important;
        padding: 0 !important;
      }
    }
  }
  &__media-body {
    padding-bottom: 21px;
  }
  &__row {
    @include media-breakpoint-down(lg) {
      justify-content: space-around;
    }
  }

  .mobile {
    @include media-breakpoint-up(md) {
      display: none !important;
    }
  }
  .mobile-hidden {
    @include media-breakpoint-down(lg) {
      display: none !important;
    }
  }
  .pc {
    @include media-breakpoint-up(md) {
      display: flex !important;
    }
  }
}
