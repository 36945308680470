@import 'styles/share';

.wrapper {
    position: relative;
    overflow: hidden;
    border-radius: 8px;
    
    video {
        object-fit: cover;
        width: 100%;
        height: auto;
    }

    .btnWrapper {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        color: white;
        font-size: 1rem;
        background: transparent;
        border: none;
        width: 100%;

        @include media-breakpoint-up(md) {
            font-size: 4rem;
        }

        @include media-breakpoint-up(lg) {
            font-size: 6rem;
        }
    }
}