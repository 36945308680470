@import 'styles/share';

.expect-result {
  color: var(--textColor);
  text-align: center;
  &__wrapper {
    width: 100%;
    height: auto;
    padding-bottom: 5em;
    background-attachment: fixed !important;
    @include media-breakpoint-down(md) {
      padding-bottom: 52px;
      background-attachment: scroll !important;
    }
  }
  &__content {
    @include media-breakpoint-down(md) {
      margin: 0 23px;
    }
    p {
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    max-width: 534px;
    margin: 0 auto 37px;
    }
  }
  &__title {
    font-family: var(--font-bebas-neue);
    font-size: 40px;
    letter-spacing: 2px;
    padding-top: 2.5em;
    @include media-breakpoint-down(lg) {
      font-size: 32px;
      letter-spacing: 2.56px;
      line-height: 35px;
      width: 60%;
      margin: 0 auto;
    }
  }
  p {
    font-family: var(--font-roboto), sans-serif;
    font-size: 16px;
    letter-spacing: 0;
    margin-top: 32px;
    line-height: 24px;
    span {
      font-family: var(--font-roboto), sans-serif;
      font-weight: bold;
    }
  }
  &__text-yellow {
    color: #f9a826;
  }
  &__week-list {
    margin: 0 auto;
    position: relative;
    padding: 0;
    @include media-breakpoint-up(lg) {
      max-width: 800px;
    }
  }

  &__progress {
    list-style: none;
    padding: 0;
    margin: 0 auto 30px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
  }

  &__card-item {
    z-index: 2;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding: 0 12px;
    position: relative;
    @include media-breakpoint-up(lg) {
      padding: 0 20px;
    }
    span {
      font-size: 16px;
      font-family: var(--font-roboto), sans-serif;
      font-weight: bold;
      letter-spacing: 0;
      line-height: 19px;
      @include media-breakpoint-down(lg) {
        margin: 0;
        font-size: 14px;
        line-height: 16px;
        font-family: var(--font-roboto), sans-serif;
        font-weight: 500;
      }
    }
    span.title {
      margin-top: 10px;
    }
    img {
      width: 120px;
      height: auto;
      @include media-breakpoint-down(md) {
        width: 59px;
        height: 59px;
      }
    }
    &:before {
      content: '' !important;
      display: block;
      position: absolute;
      left: 0;
      right: 0;
      z-index: -1;
      background: #fff;
      height: 5px;
      top: 35%;
      @include media-breakpoint-down(sm) {
        top: 30%;
      }
    }
    &:first-child:before {
      left: 50%;
      right: -50%;
    }
    &:nth-child(2):before {
      right: -50%;
      left: 50%;
    }
    &:nth-child(3):before {
      display: block;
      position: absolute;
      left: 50%;
      right: -85%;
      z-index: -1;
      background: unset;
      border: 3px dashed #f9a826;
      height: 5px;
      top: 35%;
      @include media-breakpoint-down(sm) {
        right: -76%;
        top: 30%;
      }
    }
    &--active {
      margin-left: 5em;
      @include media-breakpoint-down(lg) {
        margin: 0 5px;
      }
      img {
        border: 4px solid #f9a826;
        border-radius: 50%;
      }
      &:before {
        background: unset;
        display: block;
        position: absolute;
        left: 0;
        right: 50%;
        z-index: -1;
        height: 5px;
        top: 35%;
      }
    }
  }
  &__thumb-caption {
    font-family: var(--font-lato), sans-serif;
    font-weight: 900;
    position: absolute;
    top: 20%;
    left: 50%;
    transform: translateX(-50%);
    text-shadow: 0 1px 2px rgba(0,0,0,.7);
    @include media-breakpoint-down(md) {
      top: 14%;
      line-height: 16px;
      font-size: 14px;
    }
    div,
    span {
      font-size: 22px;
      line-height: 27px;
      font-weight: 900;
      letter-spacing: 0;
      @include media-breakpoint-down(md) {
        font-size: 14px;
        line-height: 16px;
      }
    }
  }

  &__see-programs {
    background-color: var(--mainColor);
    font-size: 20px;
    font-family: var(--font-roboto), sans-serif;
    line-height: 24px;
    color: var(--textColor);
    border-radius: 0;
    padding: 15px 40px;
    margin-top: 2em;
    @include media-breakpoint-down(md) {
      padding: 9px 2em;
    }
    &:hover {
      background-color: var(--mainColor);
      color: #fff;
      opacity: 0.9;
    }
  }
}
