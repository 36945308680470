@import "styles/share";

.container {
  margin-bottom: 30px;
}

.title {
  color: var(--textColor);
  font-family: var(--font-bebas-neue);
  font-size: 20px;
  letter-spacing: 2px;
  line-height: 22px;
  text-align: center;
  border: 0;
  padding: 0 0 15px 0;
}

.video__wrapper {
  display: flex;
  justify-content: center;
  align-items: center;

  :global {
    video {
      width: 85%;
      margin: 0 auto;

      @include media-breakpoint-down(lg) {
        width: 95%;
      }
    }
  }
}
