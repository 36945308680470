@import "styles/share";

.fast-easy {
  :global {
    .wrapper {
      background-color: rgba(#A50027, 0.45);
      min-height: 408px;
      color: var(--textColor);
    }
  }
  &__title {
    font-size: 40px;
    font-family: var(--font-bebas-neue);
    padding-top: 96px;
    @include media-breakpoint-down(md) {
      font-size: 32px;
      margin: 0 47px;
    }
  }
  &__sub-title {
    font-size: 16px;
    font-family: var(--font-roboto), sans-serif;
    letter-spacing: 0;
    line-height: 28px;
    margin-top: 1.5em;
    padding: 0 40px;
    @include media-breakpoint-down(xl) {
      padding: 0 27px;
    }
    @include media-breakpoint-down(md) {
      padding: 0 5px;
    }
  }
  &__caption {
    margin: 0 auto;
    text-align: center;
    p {
      @include media-breakpoint-down(lg) {
        font-size: 16px;
        line-height: 24px;
      }
    }
  }
  &__list-services {
    margin-top: 30px;
    padding-bottom: 1.6em;
    justify-content: center;

    :global {
      .card {
        color: #fff;
        border: none;
        border: 2px solid var(--textColor);
        border-radius: 4px;
        margin: 0 1.8em;
        padding: 12px 10px;
        background-color: transparent;
        max-width: 140px;

        &:active,
        &:focus,
        &:hover {
          cursor: pointer;
          border: 2px solid var(--mainColor);
          background-color: var(--mainColor);
        }

        @include media-breakpoint-down(lg) {
          margin: 10px auto;
          min-height: 106px;
        }

        img {
          width: auto;
          height: 32px;
          margin: 10px auto 0;
        }

        span {
          font-size: 14px;
          font-family: var(--font-roboto), sans-serif;
          font-weight: 900;
          letter-spacing: 0.11px;
          line-height: 16px;
          margin-top: 1em;
        }
      }
    }
  }
}
