@import "styles/share";

.accordion {
  width: 85%;
  margin: 0 auto;

  @include media-breakpoint-down(lg) {
    width: 95%;
    margin: 0 auto;
  }

  :global {
    .card {
      background-color: transparent!important;
      border-top: 1px solid var(--textColor)!important;
      border-bottom: 1px solid var(--textColor)!important;
      border-radius: 0!important;
      .card-body {
        color: var(--textColor);
      }
    }
    .card-header {
      text-align: left;
      .btn:focus,
      .btn.focus {
        box-shadow: none;
      }
    }
    .btn-link {
      color: #f9a826;
      font-family: var(--font-roboto), sans-serif;
      font-size: 16px;
      font-weight: 900;
      letter-spacing: 0;
      line-height: 16px;
      text-align: left;
      padding: 0;
      width: 100%;
      padding-right: 15px;
      text-transform: initial;
      margin-bottom: 0;
      margin-left: 10px;
      text-decoration: none;
      .fa {
        font-size: 14px;
        color: var(--textColor);
      }
      &:hover {
        background-color: unset;
        text-decoration: underline
      }
    }
    .card-body {
      font-family: var(--font-roboto), sans-serif;
      font-size: 16px;
      letter-spacing: 0;
      line-height: 24px;
      padding: 0 2em 10px 2em;
    }
  }

  &__title {
    color: var(--textColor);
    font-family: var(--font-bebas-neue);
    font-size: 20px;
    letter-spacing: 2px;
    line-height: 22px;
    text-align: center;
    border: 0;
    padding: 0 0 30px 0;
  }

  &__add-more {
    color: var(--textColor);
    font-family: var(--font-roboto), sans-serif;
    font-size: 14px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 20px;
    padding: 20px 16px;
    margin: 0 auto;
    display: block;
    outline: none;
    &:focus {
      box-shadow: unset;
    }
    &:active,
    &:hover {
      // color: var(--mainColor)!important;
      background-color: unset;
      border: 0!important;
    }

    :global {
      .fa {
        font-size: 18px;
        margin-left: 5px;
        width: 18px;
      }
    }

    i {
      vertical-align: bottom;
    }
  }
}
