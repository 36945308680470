@import 'styles/share';

.swipper-wrapper {
  min-height: 825px;
  position: relative;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 100%;
  @include media-breakpoint-down(md) {
    min-height: 625px;
  }
  @include media-breakpoint-down(md) {
    min-height: 462px;
  }
  @media (min-width: 768px) and (max-width: 991.98px) {
    background-image: image-set(url('/static/images/m-bg-slide1.jpg'));
  }
  @media (min-width: 1024px){
    background-image: image-set(url('/static/images/bg-slider1.jpg'));
  }
}



.swiper-container {
  width: 100%;
  height: auto;
  transition: all 0.4s ease;
  // @include media-breakpoint-down(md) {
  //   max-height: unset;
  // }
  &__swiper {
    transition: all 0.4s ease;
    // height: 100vh;
    // background: #000000;

    &:global.swiper-initialized {
      min-height: 825px;
      @include media-breakpoint-down(md) {
        min-height: 625px;
    
      }
      @include media-breakpoint-down(md) {
        min-height: 462px;
      }
    }
  }
  &__arrow-next {
    color: var(--textColor);
    right: 30px;
    &:after {
      font-size: 68px;
      font-weight: bold;
      @include media-breakpoint-down(md) {
        font-size: 25px;
      }
    }
    &.swiper-button-disabled {
      opacity: 0.35;
    }
  }
  &__arrow-prev {
    color: var(--textColor);
    left: 30px;
    &:after {
      font-size: 68px;
      font-weight: bold;
      @include media-breakpoint-down(lg) {
        font-size: 25px;
      }
    }
    &.swiper-button-disabled {
      opacity: 0.35;
    }
  }
  &__img-wrapper {
    background: #000000;
    position: relative;
    width: 100%;
    min-height: 825px;
    @include media-breakpoint-down(md) {
      min-height: 625px;

    }
    @include media-breakpoint-down(md) {
      min-height: 462px;
    }
    overflow: hidden;
    transition: all 0.4s ease;

    &--wait {
      background: #000000;
      height: 100vh;
    }
  }
  &__img {
    position: absolute;
    width: 100%;
    height: 100%;
  }
  &__caption {
    position: absolute;
    top: 24%;
    left: 8%;
    font-family: var(--font-bebas-neue), fantasy, sans-serif;
    color: var(--textColor);
    letter-spacing: 0;

    &.custom-slide {
      @include media-breakpoint-down(md) {
        text-align: left;
      }
      .swiper-container__text {
        font-size: 16px;
        font-family: var(--font-lato), sans-serif;
        font-style: italic;
        text-transform: unset;
        font-weight: 500;
      }
      .swiper-container__title,
      .swiper-container__sub-title {
        font-size: 134px;

      }
      .swiper-container__title {
        @include media-breakpoint-down(lg) {
          font-size: 51px;
          letter-spacing: 0;
          line-height: 50px;
        }
      }
      .swiper-container__sub-title {
        @include media-breakpoint-down(lg) {
          font-size: 55px;
        }
      }
      a {
        font-size: 20px;
      }
      button {
        margin-top: 15px;

        @include media-breakpoint-down(lg) {
          margin: 36px 0;
        }
      }
    }
  }
  &__title {
    font-size: 80px;
    letter-spacing: 3px;
    text-transform: uppercase;
    @include media-breakpoint-down(lg) {
      font-size: 31.35px;
      letter-spacing: 2px;
    }
  }
  &__sub-title {
    font-size: 252px;
    letter-spacing: 0;
    line-height: 0.8;
    margin-bottom: 0;
    text-transform: uppercase;
    @include media-breakpoint-down(lg) {
        font-size: 110px;
        letter-spacing: 0;
    }
  }
  &__text {
    font-size: 32px;
    letter-spacing: 1.6px;
    text-transform: uppercase;
    @include media-breakpoint-down(lg) {
        font-size: 16px;
        margin: 0;
        letter-spacing: 0.8px;
    }
  }
  &__brand {
    font-family: var(--font-lato), sans-serif;
    font-style: italic;
    font-size: 14px;
  }
  &__move-program {
    margin: 0.5em 0;
    text-transform: uppercase;
    padding: 13px 51px;
    font-size: 20px;
    min-width: 200px;
    font-family: var(--font-roboto), sans-serif;
    border: 0;
    font-weight: 500;
    @include media-breakpoint-down(md) {
      padding: 10px 20px;
      font-size: 16px;
      min-width: 155px;
      margin: 36px 0;
    }
    &:hover {
      background-color: #F9A826;
      color: #fff;
    }
    &:active{
      background-color: #F9A826 !important;
      color: #fff;
    }
  }

  &__video-wrapper {
    position: absolute;
    top: auto;
    bottom: 100px;
    width: 22%;
    right: 12%;

    @include media-breakpoint-down(md) {
      width: 35%;
      right: 8%;
      bottom: 50px;
    }
  }
}